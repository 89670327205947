import React from "react";
import "./about.css";
import Particles from "react-tsparticles";
import { GrReactjs } from "react-icons/gr";
import { DiNodejs } from "react-icons/di";
import { DiJavascript } from "react-icons/di";
import { SiMongodb } from "react-icons/si";
import { SiMysql } from "react-icons/si";
import { FaAws } from "react-icons/fa";
import { SiAmazonalexa } from "react-icons/si";
import { FaSwift } from "react-icons/fa";

import { useHistory } from "react-router-dom";
// import { FaBeer } from 'react-icons/fa'
import confettiParticles from "../../Assets/json/ConfettiParticles.json";
import myImage from "../../Assets/image/MyProfile.JPG";
import TopNav from "../TopNav/topNav";
import BottomNav from "../BottomNav/bottomNav";

export default function About() {
  const technicalSkill = [
    {
      id: 1,
      name: "HTML",
    },
    {
      id: 2,
      name: "CSS",
    },
    {
      id: 3,
      name: "JAVA Script",
    },
    {
      id: 4,
      name: "React Js",
    },
    {
      id: 5,
      name: "React-Router",
    },
    {
      id: 6,
      name: "Redux",
    },
    {
      id: 7,
      name: "Material UI",
    },
    {
      id: 8,
      name: "Tailwind CSS",
    },
    {
      id: 9,
      name: "NPM",
    },
    {
      id: 10,
      name: "Node Js",
    },
    {
      id: 11,
      name: "Express",
    },
    {
      id: 12,
      name: "Mongo DB",
    },
    {
      id: 13,
      name: "MQTT",
    },
    {
      id: 14,
      name: "Web-Socket",
    },
    {
      id: 15,
      name: "AWS Service",
    },
    {
      id: 16,
      name: "React Native",
    },
    {
      id: 17,
      name: "Next Js",
    },
    {
      id: 18,
      name: "Typescript",
    },
  ];
  const history = useHistory();
  //Code from Particle js
  const particlesInit = (main) => {
    console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  };

  const particlesLoaded = (container) => {
    console.log(container);
  };

  //Function to contact about page
  const loginOnClick = () => {
    history.push({
      pathname: "/contact",
    });
  };

  //Navigating to Project List
  const myProjectOnClick = () => {
    history.push({
      pathname: "/projects",
    });
  };

  return (
    <div className="about">
      <Particles
        className="particles_view"
        id="tsparticles"
        options={confettiParticles}
        init={particlesInit}
        loaded={particlesLoaded}
      />
      <TopNav />
      <div className="about_Container">
        <div className="profile_image">
          <img src={myImage} className="profile_image_img" alt="profileImg" />
          <button className="contact_me_Button" onClick={loginOnClick}>
            Contact Me
          </button>
        </div>
        <div className="technical__skill__container">
          <h3>Technical Skills </h3>
          <div className="technical__skill_lst_container">
            {technicalSkill.map((item) => {
              return <p key={item.id}>{item.name}</p>;
            })}
          </div>
        </div>
        <div className="text_container">
          <div className="header_text">
            <h2>Know More About Me</h2>
            <h5>
              Hi, I am Rajkishore Jena a Software Developer Having Experience 3+
              Years{" "}
            </h5>
          </div>
          <div className="icons_container">
            <div className="icons_row">
              <GrReactjs className="react_icons" color="#2196f3" />
              <DiNodejs className="react_icons" color="#388e3c" />
              <DiJavascript className="react_icons" color="#ffc107" />
              <FaSwift className="react_icons" color="#ff3d00"></FaSwift>
            </div>
            <div className="icons_row">
              <SiMongodb className="react_icons" color="#2e7d32" />
              <SiMysql className="react_icons" color="#2196f3" />
              <FaAws className="react_icons" color="#f4511e" />
              <SiAmazonalexa className="react_icons" color="#29b6f6" />
            </div>
            <button className="my_project_Button" onClick={myProjectOnClick}>
              My Projects
            </button>
          </div>
        </div>
      </div>
      <div className="about__bottom__div">
        <BottomNav />
        {/* <p className="about_bottom__P"> Copyright © 2022 Learn Tech Simple.</p> */}
      </div>
      {/* <div className="about__bottom">
        <p> Copyright © 2022 Learn Tech Simple.</p>
      </div> */}
    </div>
  );
}
