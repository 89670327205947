import "./App.css";
import Home from "./components/Home/home";
import About from "./components/About/about";
import Contact from "./components/Contact/contact";
import ProjectList from "./components/Project/ProjectList";
// import TopNav from './components/TopNav/topNav';
import { Route, BrowserRouter, Switch } from "react-router-dom";
import React, { useState } from "react";

function App() {
  const [isLoading, setIsLoading] = useState(false);

  useState(() => {
    setIsLoading(true);
    const timeOut = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    // return clearTimeout(timeOut);
    return () => clearTimeout(timeOut);
  }, []);
  return (
    <React.Fragment>
      {isLoading ? (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <div className="App">
          <BrowserRouter>
            <Switch>
              <Route path="/" exact render={() => <Home />} />
              <Route path="/about" exact render={() => <About />} />
              <Route path="/contact" exact render={() => <Contact />} />
              <Route path="/projects" exact render={() => <ProjectList />} />
            </Switch>
          </BrowserRouter>
        </div>
      )}
    </React.Fragment>
  );
}

export default App;
