import React, { useEffect, useState } from "react";
import "./topNav.css";
import Logo from "../../Assets/image/logo.png";
import { BiMenuAltRight } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import { NavLink } from "react-router-dom";

const TopNav = () => {
  const [menuView, setMenuView] = useState(false);
  const [showNav, setShowNav] = useState(false);

  const handleShowMenu = () => {
    setMenuView(!menuView);
  };
  const handleCloseMenu = () => {
    setMenuView(!menuView);
  };

  const transitionNavBar = () => {
    if (window.scrollY > 100) {
      setShowNav(true);
    } else {
      setShowNav(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", transitionNavBar);

    return () => {
      window.removeEventListener("scroll", transitionNavBar);
    };
  }, []);
  return (
    <nav>
      {!menuView && (
        <div className={`topNav_div ${showNav && "topNav__div__black"}`}>
          <div className="logo_div">
            <NavLink to="/">
              <img src={Logo} alt="log" className="logo" />
            </NavLink>
          </div>
          <div className="right_div">
            <ul className="links">
              <li>
                <NavLink to="/" className="active">
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink to="/about">About Me</NavLink>
              </li>
              <li>
                <NavLink to="/contact">Contact</NavLink>
              </li>
              <li>
                <NavLink to="/projects">Project</NavLink>
              </li>
              {/* <li>
                <a href="#products">Blogs</a>
              </li> */}
            </ul>
          </div>
          <BiMenuAltRight className="menu__icon" onClick={handleShowMenu} />
        </div>
      )}
      {menuView && (
        <div className="mobile__menu__view">
          <div className="close__button__div">
            <AiOutlineClose
              className="nav__close_button"
              onClick={handleCloseMenu}
            />
          </div>
          <ul className="mobile__menu__links">
            <li>
              <NavLink to="/" className="active">
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/about">About Me</NavLink>
            </li>
            <li>
              <NavLink to="/contact">Contact</NavLink>
            </li>
            <li>
              <NavLink to="/projects">Project</NavLink>
            </li>
            {/* <li>
                <a href="#products">Blogs</a>
              </li> */}
          </ul>
        </div>
      )}
    </nav>
  );
};

export default TopNav;

// HOME
// OUR SERVICES
// PORTFOLIO
// TESTIMONIALS
// PRODUCTS
// NEWSLETTER
