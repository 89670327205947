import React from "react";
import "./ProjectList.css";
import Particles from "react-tsparticles";
import Tunnel from "../../Assets/json/Tunnel.json";
import ProjectCard from "./ProjectCard/ProjectCard";
// import Project1 from "../../Assets/image/ReactProject.png";
import NetflixClone from "../../Assets/image/NetflixClone.png";
import AirbnbClone from "../../Assets/image/AirbnbClone.png";
import TopNav from "../TopNav/topNav";
import BottomNav from "../BottomNav/bottomNav";
import { BsGithub } from "react-icons/bs";
const ProjectList = () => {
  //Code from Particle js
  const particlesInit = (main) => {
    console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  };

  const particlesLoaded = (container) => {
    console.log(container);
  };

  const cardData = [
    {
      id: "01",
      image: NetflixClone,
      Title: "Netflix Basic Clone",
      Description:
        "Netflix Basic Web App clone, using Redux, Firebase, TMDB and React-Router",
      link: "https://github.com/rajkishorejena/sample-video-web-app-",
      liveUrl: "https://netflix-clone-webapp-b912a.web.app/",
    },
    {
      id: "02",
      image: AirbnbClone,
      Title: "Airbnb Basic Clone",
      Description:
        "Airbnb Basic Web App Clone, using Tailwind Css and React date range library",
      link: "https://github.com/rajkishorejena/airbnb-Clone",
      liveUrl: "https://airbnb-clone-basic-two.vercel.app",
    },
  ];

  const navigateToGitRepo = () => {
    const url = "https://github.com/rajkishorejena?tab=projects";
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <div className="project_list">
      <Particles
        className="particles_view"
        id="tsparticles"
        options={Tunnel}
        init={particlesInit}
        loaded={particlesLoaded}
      />
      <TopNav />
      <h1 style={{ color: "#ffffff", paddingTop: "3rem" }}>My Project List</h1>
      <div className="project_cards">
        <ProjectCard cardData={cardData} />
        <div className="last__project__card" onClick={navigateToGitRepo}>
          <h3>
            Remaining Project Will be upload soon..😀..Visit My Github Account
            for Explore More Examples.
            <BsGithub />
          </h3>
        </div>
      </div>
      <div className="projectList__bottom">
        <BottomNav />
      </div>
    </div>
  );
};

export default ProjectList;
