import React from "react";
import "./ProjectCard.css";
import { BsGithub } from "react-icons/bs";
const ProjectCard = (props) => {
  console.log("Card Data", props.cardData.length);
  const navigateUrlHandler = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  return (
    <>
      {props.cardData.length >= 0 ? (
        <>
          {props.cardData.map((list, index) => (
            <div
              className="project_card"
              key={list.id}
              onClick={() => navigateUrlHandler(`${list.liveUrl}`)}
            >
              <img className="project_Image" src={list.image} alt="Project" />
              <div className="project__card__bottom">
                <div className="project__card__id__div">
                  <h3>{list.id}</h3>
                  <BsGithub
                    className="project__card__github__icon"
                    onClick={() => navigateUrlHandler(`${list.link}`)}
                  />
                </div>
                <h4>{list.Title}</h4>
                <p>{list.Description}</p>
              </div>
            </div>
          ))}
        </>
      ) : null}
    </>
  );
};

export default ProjectCard;
