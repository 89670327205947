import React from "react";
import "./contact.css";
import Particles from "react-tsparticles";
import Lottie from "react-lottie";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, TextareaAutosize } from "@material-ui/core";
import Growing from "../../Assets/json/Growing.json";
import contactMeData from "../../Assets/lotties/contact-us.json";
// import animationData from "../../Assets/lotties/web-development.json";
import TopNav from "../TopNav/topNav";
import BottomNav from "../BottomNav/bottomNav";
import { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Material Ui styles
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      // width: "45ch",
      // backgroundColor:'#ffff'
    },
  },
}));

export default function Contact() {
  //pop after successfully send message
  const notify = () =>
    toast.success(" ✅ Successfully Send ✉️ Message!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  const form = useRef();
  //Importing the Material Ui Style
  const classes = useStyles();
  //Code from Particle js
  const particlesInit = (main) => {
    console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  };

  const particlesLoaded = (container) => {
    console.log(container);
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  //Lottie Default Code
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: contactMeData,
    rendererSettings: {
      preserveAspectRatio: "xMiYMid slice",
    },
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    console.log("value", name, ":", email);

    emailjs
      .sendForm(
        "service_qd3by79",
        "template_7ij9y8b",
        form.current,
        "_ZiBi9zweWG7qMWIU"
      )
      .then(
        (result) => {
          console.log("Success Message", result.text);
          if (result.text === "OK") {
            notify();
          }
        },
        (error) => {
          console.log(error.text);
        }
      );
    setEmail("");
    setMessage("");
    setName("");
    setSubject("");

    // console.log("current form", form.current);
  };
  return (
    <div className="main_contact_div">
      <Particles
        className="particles_view"
        id="tsparticles"
        options={Growing}
        init={particlesInit}
        loaded={particlesLoaded}
      />
      <div className="contact__top__nav">
        <TopNav />
      </div>
      <div className="contact_container">
        <div className="lottie_container">
          <Lottie options={defaultOptions} />
        </div>
        <div className="email_details_container">
          <h1>Contact Me</h1>
          <h3>
            Send Me A Message <span>to (rajkishorejena0816@gmail.com)</span>
          </h3>
          <form
            className={classes.root}
            id="form_material"
            noValidate
            autoComplete="off"
            ref={form}
            // onSubmit={formSubmitHandler}
          >
            <TextField
              name="user_name"
              className="form_material_textField"
              required
              id="outlined-basic"
              label="Your Name "
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              name="user_email"
              className="form_material_textField"
              required
              id="outlined-basic"
              label="Your Email "
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              name="subject"
              className="form_material_textField"
              required
              id="outlined-basic"
              label="Your Subject "
              variant="outlined"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
            <TextareaAutosize
              name="message"
              className="form_material_textArea"
              aria-label="minimum height"
              minRows={3}
              placeholder="Type Your Message Here"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </form>
          <button className="send_me_Button" onClick={formSubmitHandler}>
            Send Message
          </button>
        </div>
      </div>
      <div className="contact__bottom__nav">
        <BottomNav />
      </div>
      <ToastContainer />
    </div>
  );
}
