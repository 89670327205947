import React from "react";
import "./home.css";
import Particles from "react-tsparticles";
import Typical from "react-typical";
import { Player } from "@lottiefiles/react-lottie-player";
// import Lottie from "react-lottie";
// import { motion } from "framer-motion";
// import animationData from "../../Assets/lotties/web-development.json";
import Particle1 from "../../Assets/json/ColorsParticles.json";
import { useHistory } from "react-router-dom";
import TopNav from "../TopNav/topNav";
import BottomNav from "../BottomNav/bottomNav";

const Home = () => {
  const history = useHistory();

  //Code from Particle js
  const particlesInit = (main) => {
    console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  };

  const particlesLoaded = (container) => {
    console.log(container);
  };

  //Lottie Default Code
  // const defaultOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: animationData,
  //   rendererSettings: {
  //     preserveAspectRatio: "xMidYMid slice",
  //   },
  // };

  //Function to move about page
  const aboutNavigateHandler = () => {
    history.push({
      pathname: "/about",
    });
  };

  const viewResumeHandler = () => {
    window.open(
      "https://drive.google.com/file/d/1Zqd619A99-awBYBaDkP2ul4pkq1mmxXP/view?usp=share_link",
      "_blank",
      "noreferrer"
    );
  };
  return (
    <div className="home_View">
      <Particles
        className="particles_view"
        id="tsparticles"
        options={Particle1}
        init={particlesInit}
        loaded={particlesLoaded}
      />

      <div className="home_Container">
        <TopNav />
        <div className="hero_container">
          <div className="lottie_Animation">
            {/* <Lottie options={defaultOptions} /> */}
            <Player
              autoplay
              loop
              src="https://assets9.lottiefiles.com/packages/lf20_8zzfnwv8.json"
              // style={{ height: "300px", width: "300px" }}
            ></Player>
          </div>
          <div className="text_Container">
            <div className="text__container__tags">
              <h1>
                Hi <span>ALL!</span>..🙋🏻‍♂️
              </h1>
              <h2>
                I am <span>RAJKISHORE JENA</span>
              </h2>
              <h3>
                <Typical
                  steps={[
                    "A Design Engineer ",
                    3000,
                    "A Mern Stack Developer ",
                    3000,
                    "A Hybrid App Developer ",
                    3000,
                    "AWS Developer",
                    3000,
                  ]}
                  loop={Infinity}
                  wrapper="p"
                />
              </h3>
            </div>
            <div className="home__button__div">
              <button className="know_more_button" onClick={viewResumeHandler}>
                View My Resume
              </button>
              <button
                className="know_more_button"
                onClick={aboutNavigateHandler}
              >
                Know More
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <h1>Home View</h1>
    

      {/* <Lottie 
	    options={defaultOptions}
        height={400}
        width={400}
      /> */}
      {/* <Particles id="tsparticles" options={Particle1} init={particlesInit} loaded={particlesLoaded} /> */}
      <BottomNav />
    </div>
  );
};

export default Home;
